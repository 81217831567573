<template>
    <div>
        <div class="container-fluid container-head" style="position: relative;bottom: 75px;margin-top:100px;">

            <div class="row justify-content-center">
                <div class="col-md-7 row justify-content-center mt-5">
                    <div class="col-md-6 text-center">
                        <a class="navbar-brand"><img id="logo" class="img-navbar" style="height:150px;cursor:pointer"
                        src="img/logoTioToto_remove.png"></a>
                    </div>
                </div>


                <div class="col-md-7 row justify-content-center mt-5">
                    <div class="col-md-6 text-center">
                        <button class="btn lift lift-sm w-100 button-menu" style="background-color:#259dc7;" type="button" @click="redirect('');">Cargar Pedido <i class="fa-solid fa-print"></i></button>
                    </div>
                </div>
                <div class="col-md-7 row justify-content-center mt-5">
                    <div class="col-md-6 text-center">
                        <button class="btn lift lift-sm w-100 button-menu" style="background-color:#f1973b;" type="button" @click="redirect('price');">Lista de Precios <i class="fa-solid fa-credit-card"></i></button>
                    </div>
                </div>
                <div class="col-md-7 row justify-content-center mt-5">
                    <div class="col-md-6 text-center">
                        <button class="btn lift lift-sm w-100 button-menu" style="background-color:rgb(149, 193, 35);" type="button" @click="redirectWP();"> Whatsapp <i class="fa-brands fa-whatsapp"></i></button>
                    </div>
                </div>
                <div class="col-md-7 row justify-content-center mt-5">
                    <div class="col-md-6 text-center">
                        <button class="btn lift lift-sm w-100 button-menu" style="background-color:#b5b2b3;" type="button" @click="redirect('pedidos');"> Mis Pedidos <i class="fa-solid fa-cart-shopping"></i></button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import SectionHead from '@/components/order/Section.vue'
import router from '../router';
export default {
    name: 'menu',
    components: {
        SectionHead
    },
    mounted() {
    },
    data() {
        return {
        }
    },
    methods:{
        redirect(path){
            router.push("/"+path);
        },
        redirectWP(){
            window.open('https://wa.me/+5491163520103?text=Hola%20tengo%20una%20consulta');
        }
        
    },
    computed: {

    }
}
</script>
  
<style scoped>
.button-menu{
    padding: 12px;
    border-radius: 7px;
    color:white;
}

</style>
  